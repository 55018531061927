import React from 'react';

import './preloader.css'; 

export const Preloader: React.FC = () => {

    return (
        <>
            <div className='preloader'>
                Loading
            </div>
        </>
    );
};
